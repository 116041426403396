* {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}

html {
  background-color: var(--background);
  padding: none;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  color: var(--black);
}

.react-hot-toast {
  font-size: 1.4rem;
}

/* Color Palette */
:root {
  --accent: #0097B2;
  --black: #000;
  --white: #fff;
  --dark_grey: #68676E;
  --green: #50BB25;
  --background: #F2F2F2;
  --grey_blue: #6C7AA0;
  --red: #DC3546;
  --grey: #DEE3ED;
  --dark_blue: #302CD7;
}

/* Text Palette */
.title-huge {
  font-size: 2.4rem;
  font-weight: 600;
}

.title-big {
  font-size: 2rem;
  font-weight: 600;
}

.title-light {
  font-size: 2rem;
  color: var(--dark_grey);
}

.title-medium {
  font-size: 1.8rem;
  font-weight: 600;
}

.title {
  font-size: 1.6rem;
  font-weight: 600;
}

.title-small {
  font-size: 1.4rem;
  font-weight: 600;
}

.content {
  font-size: 1.4rem;
}

.content-second {
  font-size: 1.4rem;
  color: var(--grey_blue);
}

.content-focus {
  font-size: 1.4rem;
  color: var(--accent);
}

.content-small {
  font-size: 1.2rem;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1.4rem;
}

/* Buttons */
.btn {
  background-color: var(--accent);
  border-radius: 12px;
  width: 242px;
  height: 40px;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  align-content: center;
  color: var(--white);
  border: none;
  cursor: pointer;
  transition: all 0.4s;
  margin-top: 32px;
}

.btn:hover {
  background-color: #003C5C;
}

/* Containers */
.container {
  text-align: center;
}

.first-box {
  display: flex;
  width: 100%;
}

.content-box {
  margin: 30px 70px;
  width: 100%;
}

.element-frame ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(282px, 1fr));
  gap: 30px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.no-content {
  margin-bottom: 48px;
  height: calc(100vh - 302px);
  text-align: center;
  align-content: center;
}

.row {
  display: flex;
  gap: 16px;
  align-items: stretch;
}

.col {
  width: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

/* Spinner */
.spinner {
  border: 8px solid var(--grey);
  border-top: 8px solid var(--accent);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Generals */
.icon {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 100;
  font-size: 24px;
  color: var(--dark_grey);
}

.img-round {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: contain;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

/* Leading */
.head {
  height: auto;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 48px;
}

.head .icons .icon {
  color: var(--accent);
  font-size: 43px;
  border: var(--accent) solid 2px;
  border-radius: 50%;
  margin-left: 30px;
  background-color: var(--grey);
}

.head .search-box {
  height: 50px;
  width: 380px;
  background-color: var(--white);
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 16px;
}

.search-box input {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 12px;
  outline: none;
}

/* Login & Register */
.login-logo {
  margin-top: 1.5%;
  font-size: 5.6rem;
  font-weight: bolder;
  text-align: center;
  background-image: url('assets/images/png/logo-light.png');
  height: 220px;
  width: 350px;
  margin-left: calc(50% - 175px);
  background-size: contain;
  background-position: center;

}

.login-card {
  background-color: var(--white);
  border-radius: 8px;
  padding: 40px 60px;
  margin: 8px 27% 0 27%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.forgot-pass {
  margin-top: 32px;
  font-size: 1.4rem;
  font-weight: 600;
}

.forgot-pass p {
  color: var(--black);
}

.forgot-pass p a {
  color: var(--accent);
  text-decoration: none;
}

/* OTP */
.otp-container {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-top: 20px;
}

.otp-input {
  width: 88px;
  height: 120px;
  border: 1px solid var(--grey);
  border-radius: 8px;
  font-size: 24px;
  text-align: center;
  outline: none;
  min-height: 50px;
}

.countdown-timer {
  margin-top: 32px;
  display: flex;
  gap: 16px;
  text-align: center;
  justify-content: start;
}

.resend {
  cursor: pointer;
  font-weight: bold;
}

.resend:hover {
  color: var(--grey_blue);
}

/* TextField */
.input-container {
  position: relative;
  margin: 20px 0;
  width: 100%;
}

.input {
  width: calc(100% - 24px);
  padding: 10px 12px;
  border: 1px solid var(--grey);
  border-radius: 8px;
  outline: none;
  transition: border 0.3s ease;
  font-size: 1.4rem;
}

.input:focus {
  border: 1px solid var(--dark_grey);
}

.input-label {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  padding: 0 5px;
  color: var(--dark_grey);
  transition: all 0.3s ease;
  pointer-events: none;
  font-size: 1.6rem;
}

.input:focus+.input-label,
.input.filled+.input-label {
  top: 0;
  left: 12px;
  color: var(--dark_grey);
  font-size: 1.4rem;
}

.input.filled {
  border: 1px solid var(--dark_grey);
}

.toggle-password {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: var(--dark_grey);
  cursor: pointer;
  font-size: 1.4rem;
  outline: none;
}

/* Header */
.nav-bar {
  position: relative;
  left: 0;
  top: 0;
  width: 80px;
  height: 100vh;
  background-color: var(--white);
  text-align: center;
  position: sticky;
}

.nav-bar ul {
  padding: 1px 0;
}

.nav-bar li {
  margin: 48px 0px;
  list-style-type: none;
}

.nav-icon {
  color: var(--dark_grey);
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s;
  display: block;
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 100;
}

.nav-icon-bottom {
  position: absolute;
  bottom: 72px;
  left: 29px;
}

.nav-icon-filled {
  color: var(--accent);
  font-family: 'Material Icons Round';
}

.nav-icon-add {
  color: var(--accent);
  font-size: 36px;
  font-family: 'Material Icons Round';
}

.nav-icon:hover {
  color: var(--accent);
  font-family: 'Material Icons Round';
}

.nav-bar .nav-logo {
  margin-top: 16px;
  font-weight: 500;
  background-image: url('assets/images/png/logo-light.png');
  background-size: contain;
  background-position: center;
  width: 60px;
  height: 44px;
  margin-right: 10px;
  margin-left: 10px;
}

.nav-collapse {
  display: none;
}

.nav-icon .btn {
  display: none;
}

.shadow-box {
  box-shadow: none;
}

/* ADVERT CARD */
.advert-card {
  background-color: var(--white);
  border-radius: 8px;
  height: auto;
  padding: 32px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
}

.advert-card .corp {
  height: 107px;
  width: 100%;
}

.advert-card .corp p {
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 8px;
}

.advert-card .corp .line {
  height: 40px;
  width: 100%;
  border-bottom: 1px solid var(--grey);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.corp .line .img-round {
  position: relative;
  top: 40px;
  margin-left: 20px;
  z-index: 0;
}

.advert-card .info {
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.advert-card .info .title {
  height: 2.2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.advert-card .info .desc {
  height: calc((1.4rem * 4) + 30px);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.advert-card .amount {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.advert-card .btn {
  margin: 32px 20px 0 20px;
  width: stretch;
}

.advert-card .btn-edit {
  background-color: var(--red);
}

/* Advert Detail */
.detail-card {
  padding: 20px;
  border-radius: 18px;
  background-color: var(--white);
  width: auto;
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 280px;
  flex: 1;
}

.nocs {
  align-items: start;
}

.row-wrap {
  flex-wrap: wrap;
}

.detail-card .title-medium, .detail-card .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail-card .img-round {
  width: 55px;
  height: 55px;
}

.icon-circle {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: var(--red);
}

.icon-circle-red {
  background-color: var(--red);
}

.icon-circle-green {
  background-color: var(--green);
  text-align: center;
  align-content: center;
  color: var(--white);
  font-weight: 500;
}

.icon-circle-grey {
  background-color: var(--grey);
  width: 44px;
  height: 44px;
}

.item-row {
  padding-bottom: 16px;
  border-bottom: 0.3px solid var(--grey);
}

.detail-titlebox {
  padding-top: 48px;
  padding-bottom: 16px;
}

@media (max-width: 768px) {

  /* Tablets */
  html {
    font-size: 10px;
  }

  .login-logo {
    height: 165px;
    width: 262.5px;
    margin-left: calc(50% - 131.25px);
  }

  .login-card {
    margin: 8px auto 0 auto;
    padding: 20px 20px;
    max-width: 400px;
  }

  .first-box {
    display: block;
    width: 100%;
  }

  .content-box {
    margin: 16px 16px;
    width: auto;
  }

  .nav-bar {
    width: 100%;
    height: 80px;
    z-index: 10;
  }

  .nav-bar li {
    margin: 0;
  }

  .nav-bar .nav-logo {
    margin-bottom: 16px;
  }

  .nav-icon {
    background-color: var(--white);
    height: 60px;
    border-top: 0.5px solid var(--dark_grey);
    text-align: left;
    align-content: center;
  }

  .nav-bar li i,
  .nav-bar li div {
    padding-left: 20px;
  }

  .nav-collapse {
    display: block;
    position: absolute;
    right: 16px;
    top: 28px;
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: 100;
    font-size: 24px;
    cursor: pointer;
  }

  .nav-icon-bottom {
    display: none;
  }

  .nav-icon.none {
    display: block;
    transition: all 0.4s ease;
  }

  .nav-icon.block {
    display: none;
    transition: all 0.4s ease;
  }

  .nav-icon .btn {
    display: block;
    margin-top: 0;
    margin-right: 20px;
    width: stretch;
  }

  .shadow-box {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  }

  .head .search-box {
    width: 60%;
    height: 50px;
    max-width: 380px;
  }

  .head .icons .icon {
    font-size: 32px;
  }

  .otp-container {
    gap: 2.5%;
  }
  
  .otp-input {
    width: 12%;
    height: 15.3vw;
    min-height: 70px;
  }

  .countdown-timer {
    margin-left: 7.75%;
  }
    
  .element-frame ul {
    gap: 16px;
  }

  .detail-titlebox {
    padding-top: 16px;
    padding-bottom: 12px;
  }
}

@media (max-width: 480px) {

  /* Phones */
  html {
    font-size: 10x;
  }

  .login-logo {
    height: 165px;
    width: 262.5px;
    margin-left: calc(50% - 131.25px);
  }

  .login-card {
    margin: 8px 4% 0 4%;
    padding: 20px 20px;
  }
}